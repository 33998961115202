@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
*{
    font-family: 'Inter', sans-serif;
}
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .sider-dashboard .ant-menu-item{
padding-left: 0px !important;
padding: 28px 12px !important;
} */

.ant-menu-inline-collapsed .ant-menu-item  .ant-menu-submenu-title{
    padding-inline: 0 !important;
}

.swiper-slide {
    width: auto !important;
    height: auto !important;
    /* height: 100% !important; */
}

.title-onDoubleClick{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.main-tooltip{
    background: rgba(0, 0, 0, 0.9);
    padding: 8px  8px;
    border-radius: 8px;
    color: whitesmoke;
}

.main-tooltip .container-tooltip{
   display: flex;
   flex-direction: column;
   gap: 4px;
}
.main-tooltip .item-tooltip{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
 }

 .text-right{
    text-align: right;
 }

 .text-bold{
    font-weight: bold;
 }

 .leaflet-popup-tip {
    background-color: black;
    opacity: 0.8;
 }

.leaflet-popup-content-wrapper {
    background-color: black;
    opacity: 0.8;
    /* width: 100%; */
    box-shadow: none;
}

.leaflet-popup-close-button span{
    color: white;
}

.first-Uppercase{
    text-transform: capitalize
}

.rdt_TableCell mark{
    display: inline-block;
    white-space: nowrap
}
